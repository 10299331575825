import { BlogCard } from "@components/BlogRollV2";
import {
	BlogCategories,
	BlogNode,
	blogNodeToCmsNode,
	getBlogCategories,
} from "@components/CMS/CmsTypes";
import { TabsV2, TypographyV2 } from "@components/index";
import Paginator from "@components/Paginator/Paginator";
import { useWindowSize } from "@helpers/Hooks";
import classNames from "classnames";
import React, { HTMLProps, useEffect, useMemo, useState } from "react";
import * as styles from "./BlogList.module.scss";

type Props = {
	allBlogs: BlogNode[];
	groupedBlogs: Record<BlogCategories, BlogNode[]>;
};

const BlogList = ({ allBlogs, groupedBlogs }: Props) => {
	const { isMobile, isTablet, isDesktop, width } = useWindowSize();
	const totalOfElementsPerPage = useMemo(() => {
		if (width > 1920) {
			return 8;
		} else if (isDesktop) {
			return 6;
		} else if (isTablet) {
			return 4;
		} else if (isMobile) {
			return 3;
		}
		return 6;
	}, [isMobile, isTablet, isDesktop, width]);

	const [page, setPage] = useState(1);
	const categories = getBlogCategories();
	const [activeTab, setActiveTab] = useState<BlogCategories>(
		"All" as BlogCategories
	);
	const selectedBlogs =
		activeTab === "All"
			? allBlogs?.map(blogNodeToCmsNode)
			: groupedBlogs[activeTab]?.map(blogNodeToCmsNode);

	if (!selectedBlogs) return <></>;

	const startIndex = (page - 1) * totalOfElementsPerPage;
	const endIndex = startIndex + totalOfElementsPerPage;
	const slicedBlogs = selectedBlogs.slice(startIndex, endIndex);

	return (
		<div className={styles.wrapper}>
			<TabsV2
				wrapperClass={styles.categories}
				selectedTab={activeTab}
				onChange={(category) => {
					setPage(1);
					setActiveTab(category as BlogCategories);
				}}
        tabsClassName={styles.tabsElements}
				tabs={categories.map((category) => category as string)}
			/>
			<div className={styles.content}>
				{slicedBlogs.map((blog, index) => {
					return <BlogCard data={blog} key={index} />;
				})}
			</div>
			<Paginator
				current={page}
				onChange={(page) => setPage(page)}
				total={selectedBlogs.length}
				pageSize={totalOfElementsPerPage}
			/>
		</div>
	);
};

export default BlogList;
