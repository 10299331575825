import { graphql } from "gatsby";
import { ResourcesBlogPageProps } from "pages/blog";
import React from "react";
import {
	filterBlogCategoriesForEnv,
	BlogNode,
	BlogCategories,
} from "@components/CMS/CmsTypes";
import { groupBy } from "@helpers/groupBy";
import BlogsHero from "./_layout/BlogsHero";
import * as styles from "./blogs.module.scss";
import { PageLayout } from "@components/index";
import BlogList from "./_layout/BlogList/BlogList";

const DEFAULT_VALUE: BlogNode = {
	title: "Scientific discoveries unveiled: Exploring the wonders of the universe",
	body: {
		summary:
			"How do you create compelling presentations that wow your colleagues and impress your managers?",
	},
	relationships: {
		field_blog_category: {
			name: "All" as BlogCategories,
		},
		field_blog_feature_image: {
			field_media_image: {
				alt: "",
			},
			relationships: {
				field_media_image: {},
			},
		},
	},
	path: {
		alias: "/",
	},
} as BlogNode;

const Blog = ({ data }: ResourcesBlogPageProps) => {
	const {
		blogs: { nodes },
	} = data;
	const blogs = nodes.length
		? nodes.filter(filterBlogCategoriesForEnv)
		: [DEFAULT_VALUE];
	const groupedBlogs = groupBy(
		blogs,
		(blog) => blog.relationships.field_blog_category.name
	);

	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "blog" }}>
			<BlogsHero firstBlog={blogs[0]} />
			<BlogList groupedBlogs={groupedBlogs} allBlogs={blogs} />
		</PageLayout>
	);
};

export const pageQuery = graphql`
	{
		blogs: allNodeBlog(sort: { fields: created, order: DESC }) {
			nodes {
				title
				body {
					processed
					summary
				}
				created(formatString: "MMMM DD, YYYY")
				path {
					alias
				}
				relationships {
					field_blog_category {
						name
					}
					field_blog_feature_image {
						relationships {
							field_media_image {
								localFile {
									childImageSharp {
										gatsbyImageData(width: 684)
									}
								}
							}
						}
						field_media_image {
							alt
						}
					}
				}
			}
		}
	}
`;
export default Blog;
